import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Badge from '@mui/material/Badge';
import { useAuthContext } from '../../../hooks/useAuthContext/useAuthContext';
import {
  getNotifications,
  getUserInfo,
  setUserInfoAction,
  setUserNotificationsAction,
  resetUserNotificationAction,
  setAccountTypeAction,
  getSelectedAccount,
  setSelectedAccountAction,
} from '../../../store/ducks/inSiteAppState';
import { classNames } from '../../../utils/class-names';
import { AppRoute } from '../../../router/model/type/app-route'; // if use public api - storybook not work with AppRoute
import io from 'socket.io-client';
import { ReactComponent as IcnNotification } from '../../../assets/icons/icn-notification.svg';
import { ReactComponent as IcnProfile } from '../../../assets/icons/icn-profile.svg';
import cls from './app-bar.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import services from '../../../services';
import SearchIcon from '@mui/icons-material/Search';
import { Avatar, List, ListItem, ListItemButton, ListSubheader, TextField } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { getExternalOrgAccounts, getUserOrg } from '../../../utils';
import { HR } from '../../hr';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { resetGMBStateAction } from '../../../store/ducks/gmb-state';
import { resetGMBDashboardStateAction } from '../../../store/ducks/gmb-dashboard-state';
import { resetAdsStateAction } from '../../../store/ducks/ads-dashboard-state';
import { resetDashboardAction } from '../../../store/ducks/dashboardState';
import { resetSocialAction } from '../../../store/ducks/socialAnalyticsState';
import { resetIGAction } from '../../../store/ducks/instagramAnalyticsState';

const AppBar = () => {
  const [open, setOpen] = useState(false);
  const [socket, setSocket] = useState<any>(null);
  const dispatch = useDispatch();
  const messages: any = useSelector(getNotifications);
  const selectedAccount: any = useSelector(getSelectedAccount);
  const [isNofificationPanelopen, setIsNofificationPanelopen] = useState(false);
  const [isNofificationDotInvisible, setIsNofificationDotInvisible] = useState(true);
  const { signOut } = useAuthContext();
  const navigate = useNavigate();
  const userInfo: any = useSelector(getUserInfo);
  const userOrg: any = getUserOrg(userInfo?.permissions);
  const externalAccounts: any = getExternalOrgAccounts(userInfo?.permissions);
  const username: string = userInfo?.name ? userInfo.name.first_name + ' ' + userInfo.name.last_name : '';

  const redirectToAccountProfile = () => {
    dispatch(setAccountTypeAction('default'));
    navigate(AppRoute.ACCOUNT_PROFILE);
  };
  const redirectToBusinessProfile = () => {
    dispatch(setAccountTypeAction('default'));
    navigate(AppRoute.BUSINESS_PROFILE);
  };

  const handleNotificationPanelToggle = useCallback(() => {
    setIsNofificationPanelopen(!isNofificationPanelopen);
    setIsNofificationDotInvisible(true);
  }, [isNofificationPanelopen]);

  useEffect(() => {
    if (isNofificationPanelopen) {
      document.addEventListener('click', handleNotificationPanelToggle);
      if (userInfo?.notifications && userInfo?.notifications?.bell_amount !== 0) {
        restBellAmount();
      }
    } else {
      document.removeEventListener('click', handleNotificationPanelToggle);
    }
    return () => {
      document.removeEventListener('click', handleNotificationPanelToggle);
    };
  }, [isNofificationPanelopen, handleNotificationPanelToggle]);

  useEffect(() => {
    const s = io('wss://m.rankworks.com', {
      //reconnection: false, // Disable automatic reconnection
      // or you can use reconnectionAttempts to limit the number of retries
      reconnectionAttempts: 5, // Number of reconnection attempts
      reconnectionDelay: 1000, // Time delay between reconnections in ms
    });
    //const s = io('http://localhost:8080');
    s.on('connect', () => {
      console.log('Connected to server');
      setSocket(s);
    });

    s.on('message', (message: any) => {
      console.log('Message from server:', message);
      dispatch(setUserNotificationsAction(message));
      //setMessages((prevMessages: any) => [...prevMessages, message]);
      setIsNofificationDotInvisible(false);
    });

    s.on('disconnect', () => {
      console.log('Disconnected from server');
    });
  }, []);

  useEffect(() => {
    if (userInfo?.email && socket) {
      socket.emit('register', userInfo.email);

      if (userInfo?.notifications && userInfo?.notifications?.messages.length !== 0) {
        userInfo?.notifications?.messages.forEach((element: any) => {
          dispatch(setUserNotificationsAction(element));
        });
        if (userInfo?.notifications?.bell_amount !== 0) setIsNofificationDotInvisible(false);
      }
    }
  }, [userInfo, socket]);

  const handleRedirect = () => {
    navigate(AppRoute.GET_HELP);
  };

  const restBellAmount = async () => {
    await services.resetBellAmount();
    dispatch(resetUserNotificationAction());
  };

  const formatTimestampToDateString = (utcTimestamp: string) => {
    // Convert the timestamp to milliseconds
    const date = new Date(parseFloat(utcTimestamp) * 1000);
    // Options for formatting the date
    const options: any = { day: 'numeric', month: 'short' };
    // Format the date
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  };

  //const multiUser = userInfo?.permissions && userOrg?.organization_details?.accounts.length > 1 ? true : false;
  const multiUser = true;

  const handleClickAway = () => {
    setOpen(false);
  };

  const setWorkingAccount = async (account: any) => {
    try {
      const id = account?.account_details?._id || account?._id;
      const data = {
        customer_account_id: 'acc_' + id,
      };
      await services.setWorkingAccount(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userInfo?.permissions) setWorkingAccount(selectedAccount);
  }, [selectedAccount]);

  return (
    <>
      <div style={{ display: 'flex' }}>
        <button className={classNames(cls.iconButton, cls.withMenu)} onClick={handleNotificationPanelToggle}>
          <Badge color="secondary" variant="dot" invisible={isNofificationDotInvisible}>
            <IcnNotification />
          </Badge>
          {isNofificationPanelopen && (
            <div className={cls.notificationPanel}>
              {/* <div className={cls.panelHeader}>
                <CloseIcon className={cls.closeIcon} onClick={handleNotificationPanelToggle} />{' '}

              </div> */}
              {messages.slice(0, 10).map((message: any, index: any) => (
                <div className={cls.parent} key={index}>
                  <div className={cls.menuItem} onClick={() => handleRedirect()}>
                    You have an update on {JSON.stringify(message?.ticket_id || message?.email)}
                  </div>
                  <span
                    style={{
                      paddingLeft: '25px',
                      paddingRight: '20px',
                      fontSize: '10px',
                      color: 'gray',
                    }}
                  >
                    {formatTimestampToDateString(message?.utc)}
                  </span>
                </div>
              ))}
              {messages.length === 0 && (
                <p style={{ paddingLeft: '20px', paddingRight: '20px' }}>No Notification availabe at this momement</p>
              )}
            </div>
          )}
        </button>

        <ClickAwayListener onClickAway={handleClickAway}>
          <div style={{ display: 'flex' }}>
            <Dropdown style={{}} open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
              <MenuButton
                sx={{
                  border: 'none',
                  '&:hover': {
                    backgroundColor: 'transparent', // No background change on hover
                  },
                }}
                className={classNames(cls.iconButton, cls.withMenu)}
                onClick={() => setOpen(!open)}
              >
                <IcnProfile />
              </MenuButton>
              <Menu
                sx={{ padding: 0, zIndex: '99999 !important' }}
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [multiUser ? -179 : -79, -10], // Adjusts the x and y position; y is 15px down
                    },
                  },
                ]}
              >
                <div className={cls.menu}>
                  {multiUser ? (
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          display: 'flex',
                          borderRight: '1px solid var(--Primary-Primary-200)',
                          maxWidth: '250px',
                          overflowX: 'hidden',
                        }}
                      >
                        <List
                          aria-labelledby="nested-list-subheader"
                          style={{
                            width: '100%',
                          }}
                          subheader={
                            <ListSubheader
                              component="div"
                              id="nested-list-subheader"
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                fontFamily: 'Mulish',
                                color: 'var(--Primary-Primary-400)',
                                paddingTop: '10px',
                                width: '100%',
                              }}
                            >
                              <TextField
                                id="user_email"
                                variant="outlined"
                                fullWidth
                                size="small"
                                placeholder="Search account"
                                onChange={(event) => {}}
                                onClick={(event) => event.stopPropagation()}
                                style={{
                                  borderRadius: '0px !important',
                                  backgroundColor: 'white',
                                  paddingLeft: '5px !important',
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <SearchIcon style={{ marginRight: '5px', color: '#50648c', width: '18px' }} />
                                  ),
                                }}
                                inputProps={{
                                  style: {
                                    fontSize: '12px', // Adjust font size as needed
                                    fontFamily: 'Mulish, sans-serif', // Change font family as desired
                                    color: 'rgba(0, 0, 0, 0.6)', // Optional: Adjust the placeholder text color
                                  },
                                }}
                              />
                              {/* <span style={{ textAlign: 'left', lineHeight: '35px', color: '#64F28C' }}>
                            {selectedAccount.name}
                          </span> */}
                            </ListSubheader>
                          }
                        >
                          <HR style={{ margin: 0, marginTop: '10px' }}></HR>
                          <div
                            style={{ maxHeight: '225px', overflow: 'auto', scrollbarWidth: 'thin', paddingTop: '0px' }}
                          >
                            {userInfo?.permissions &&
                              userOrg?.organization_details?.accounts.map((item: any, index: any) => (
                                <ListItem disablePadding onClick={() => {}}>
                                  <ListItemButton
                                    style={{
                                      width: '100%',
                                      height: '35px',
                                      fontWeight: selectedAccount?._id === item?._id ? 'bold' : 'normal',
                                    }}
                                    onClick={() => {
                                      dispatch(resetDashboardAction());
                                      dispatch(setAccountTypeAction('default'));
                                      dispatch(setSelectedAccountAction(item));
                                      dispatch(resetGMBStateAction());
                                      dispatch(resetGMBDashboardStateAction());
                                      dispatch(resetAdsStateAction());
                                      dispatch(resetSocialAction());
                                      dispatch(resetIGAction());
                                      setOpen(false);
                                      navigate('/dashboard');
                                    }}
                                  >
                                    {selectedAccount?._id === item._id ? (
                                      <FiberManualRecordIcon
                                        style={{ color: '#64F28C', fontSize: '14px', marginRight: '5px' }}
                                      />
                                    ) : (
                                      <FiberManualRecordIcon
                                        style={{ color: '#001955', fontSize: '14px', marginRight: '5px' }}
                                      />
                                    )}
                                    <div
                                      style={{
                                        maxWidth: '250px', // Maximum width of the text
                                        whiteSpace: 'nowrap', // Prevent text from wrapping
                                        overflow: 'hidden', // Hide overflowing text
                                        textOverflow: 'ellipsis', // Add "..." to truncated text
                                      }}
                                    >
                                      {item.name}
                                    </div>
                                  </ListItemButton>
                                </ListItem>
                              ))}
                            <HR style={{ margin: 0 }}></HR>
                            {userInfo?.permissions &&
                              externalAccounts.map((item: any, index: any) => (
                                <ListItem disablePadding onClick={() => {}}>
                                  <ListItemButton
                                    style={{
                                      width: '100%',
                                      height: '35px',
                                      fontWeight: selectedAccount?._id === item._id ? 'bold' : 'normal',
                                    }}
                                    onClick={() => {
                                      dispatch(resetDashboardAction());
                                      dispatch(setAccountTypeAction('default'));
                                      dispatch(setSelectedAccountAction(item));
                                      dispatch(resetGMBStateAction());
                                      dispatch(resetGMBDashboardStateAction());
                                      dispatch(resetAdsStateAction());
                                      dispatch(resetSocialAction());
                                      dispatch(resetIGAction());
                                      setOpen(false);
                                      const redirectVar = item.resources[0];
                                      if (redirectVar === 'SEO') navigate('/seo');
                                      if (redirectVar === 'SMP') navigate('/social');
                                      if (redirectVar === 'GMB') navigate('/gmb');
                                      if (redirectVar === 'GA4') navigate('/dashboard');
                                      if (redirectVar === 'ADS') navigate('/advertising');
                                    }}
                                  >
                                    {selectedAccount?._id === item._id ? (
                                      <FiberManualRecordIcon
                                        style={{ color: '#64F28C', fontSize: '14px', marginRight: '5px' }}
                                      />
                                    ) : (
                                      <FiberManualRecordIcon
                                        style={{ color: '#8C96BE', fontSize: '14px', marginRight: '5px' }}
                                      />
                                    )}
                                    <div
                                      style={{
                                        maxWidth: '250px', // Maximum width of the text
                                        whiteSpace: 'nowrap', // Prevent text from wrapping
                                        overflow: 'hidden', // Hide overflowing text
                                        textOverflow: 'ellipsis', // Add "..." to truncated text
                                      }}
                                    >
                                      {item.account_details.name}
                                    </div>
                                  </ListItemButton>
                                </ListItem>
                              ))}
                          </div>
                        </List>
                      </div>
                      <div className={cls.singleUser}>
                        <div className={cls.profileSection}>
                          <Avatar
                            alt="Profile picture"
                            sx={{ width: 75, height: 75 }}
                            src={userInfo?.avatar_url}
                          ></Avatar>
                          <div className={cls.profileName}>{username}</div>
                          <div className={cls.profileEmail}>{userInfo?.email}</div>
                        </div>
                        <div className={cls.menuSection}>
                          <div className={cls.menuWrap}>
                            <div className={cls.menuIcon}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                              >
                                <path
                                  d="M1 10.4336C1 8.46712 2.64286 6.87302 6 6.87302C9.35714 6.87302 11 8.46712 11 10.4336C11 10.7464 10.7718 11 10.4902 11H1.5098C1.22825 11 1 10.7464 1 10.4336Z"
                                  stroke="#001955"
                                />
                                <path
                                  d="M7.875 2.875C7.875 3.91053 7.03553 4.75 6 4.75C4.96447 4.75 4.125 3.91053 4.125 2.875C4.125 1.83947 4.96447 1 6 1C7.03553 1 7.875 1.83947 7.875 2.875Z"
                                  stroke="#001955"
                                />
                              </svg>
                            </div>
                            <div className={cls.menuItemProfile} onClick={redirectToAccountProfile}>
                              Profile
                            </div>
                          </div>
                          {userInfo?.permissions && userOrg?.organization_details?.accounts.length > 0 && (
                            <div className={cls.menuWrap}>
                              <div className={cls.menuIcon}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="12"
                                  viewBox="0 0 13 12"
                                  fill="none"
                                >
                                  <path
                                    d="M6.44692 1.16349C6.5598 0.945502 6.88594 0.945502 6.99882 1.16349L8.46002 3.98543C8.50484 4.072 8.59149 4.132 8.69171 4.14588L11.9591 4.5984C12.2115 4.63336 12.3122 4.92899 12.1296 5.09868L9.76533 7.29525C9.6928 7.36263 9.65971 7.45971 9.67683 7.55486L10.235 10.6565C10.2781 10.8961 10.0142 11.0788 9.78847 10.9657L6.86607 9.50127C6.77642 9.45635 6.66932 9.45635 6.57967 9.50127L3.65727 10.9657C3.43152 11.0788 3.16766 10.8961 3.21078 10.6565L3.76891 7.55486C3.78603 7.45971 3.75294 7.36263 3.68041 7.29525L1.31613 5.09868C1.1335 4.92899 1.23428 4.63336 1.48668 4.5984L4.75403 4.14588C4.85425 4.132 4.9409 4.072 4.98572 3.98543L6.44692 1.16349Z"
                                    stroke="#001955"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                              <div
                                className={cls.menuItemProfile}
                                onClick={() => {
                                  dispatch(setAccountTypeAction('admin'));
                                }}
                              >
                                Admin
                              </div>
                            </div>
                          )}
                          {userInfo?.permissions && userOrg?.organization_details?.accounts.length > 0 && (
                            <div className={cls.menuWrap}>
                              <div className={cls.menuIcon}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  viewBox="0 0 12 12"
                                  fill="none"
                                >
                                  <path
                                    d="M4.33333 11V7.19602C4.33333 6.84587 4.63181 6.56202 5 6.56202H7C7.36819 6.56202 7.66667 6.84587 7.66667 7.19602V11M5.61365 1.11732L1.28032 4.04779C1.10445 4.16672 1 4.35925 1 4.56447V10.049C1 10.5742 1.44772 11 2 11H10C10.5523 11 11 10.5742 11 10.049V4.56447C11 4.35925 10.8955 4.16673 10.7197 4.04779L6.38635 1.11732C6.15504 0.960894 5.84496 0.960894 5.61365 1.11732Z"
                                    stroke="#001955"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </div>
                              <div className={cls.menuItemProfile} onClick={redirectToBusinessProfile}>
                                Account
                              </div>
                            </div>
                          )}
                        </div>
                        <div className={cls.logOutSection} onClick={signOut}>
                          Sign out
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={cls.singleUser}>
                      <div className={cls.profileSection}>
                        <Avatar
                          alt="Profile picture"
                          sx={{ width: 75, height: 75 }}
                          src={userInfo?.avatar_url}
                        ></Avatar>
                        <div className={cls.profileName}>{username}</div>
                        <div className={cls.profileEmail}>{userInfo?.email}</div>
                      </div>
                      <div className={cls.menuSection}>
                        <div className={cls.menuWrap}>
                          <div className={cls.menuIcon}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                            >
                              <path
                                d="M1 10.4336C1 8.46712 2.64286 6.87302 6 6.87302C9.35714 6.87302 11 8.46712 11 10.4336C11 10.7464 10.7718 11 10.4902 11H1.5098C1.22825 11 1 10.7464 1 10.4336Z"
                                stroke="#001955"
                              />
                              <path
                                d="M7.875 2.875C7.875 3.91053 7.03553 4.75 6 4.75C4.96447 4.75 4.125 3.91053 4.125 2.875C4.125 1.83947 4.96447 1 6 1C7.03553 1 7.875 1.83947 7.875 2.875Z"
                                stroke="#001955"
                              />
                            </svg>
                          </div>
                          <div className={cls.menuItemProfile} onClick={redirectToAccountProfile}>
                            Profile
                          </div>
                        </div>
                        <div className={cls.menuWrap}>
                          <div className={cls.menuIcon}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                            >
                              <path
                                d="M4.33333 11V7.19602C4.33333 6.84587 4.63181 6.56202 5 6.56202H7C7.36819 6.56202 7.66667 6.84587 7.66667 7.19602V11M5.61365 1.11732L1.28032 4.04779C1.10445 4.16672 1 4.35925 1 4.56447V10.049C1 10.5742 1.44772 11 2 11H10C10.5523 11 11 10.5742 11 10.049V4.56447C11 4.35925 10.8955 4.16673 10.7197 4.04779L6.38635 1.11732C6.15504 0.960894 5.84496 0.960894 5.61365 1.11732Z"
                                stroke="#001955"
                                stroke-linecap="round"
                              />
                            </svg>
                          </div>
                          <div className={cls.menuItemProfile} onClick={redirectToBusinessProfile}>
                            Account
                          </div>
                        </div>
                      </div>
                      <div className={cls.logOutSection} onClick={signOut}>
                        Sign out
                      </div>
                    </div>
                  )}
                </div>
              </Menu>
            </Dropdown>
          </div>
        </ClickAwayListener>
      </div>
    </>
  );
};

export { AppBar };
